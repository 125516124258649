import React, { useState } from 'react'
import { Modal } from '../../modal/Modal'
import styled from 'styled-components';
import '../stylesNavigation/navigationPages.css'
import ReactPlayer from 'react-player';
import { TbArrowBack } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';

export const TrenSuperior = () => {
    const [modalState1, getModalState1] = useState(false);
    const [modalState2, getModalState2] = useState(false);
    // const [modalState3, getModalState3] = useState(false);
    // const [modalState4, getModalState4] = useState(false);
    // const [modalState5, getModalState5] = useState(false);
    // const [modalState6, getModalState6] = useState(false);
    // const [modalState7, getModalState7] = useState(false);
    // const [modalState8, getModalState8] = useState(false);
    // const [modalState9, getModalState9] = useState(false);
    // const [modalState10, getModalState10] = useState(false);
    return (
        <>
			<div className='titleCategory'>
				<a className='iconBcack' href='/ganarMusculo'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Power</span>
			</div>
			<div className='titleSubcategorie'> 
				<Link to='/gluteosYPierna' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Tren Superior</h2>
				<Link to='/absYCore' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
            <ContenedorVideos>
				<div className='video1 videoTrenSuperior1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #1</h3>
					<p className='titleSubcategorieTime'>26:47</p>
				</div>
				<div className='video1 videoTrenSuperior2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #2</h3>
					<p className='titleSubcategorieTime'>23:50</p>
				</div>
				{/* <div className='video1 videoTrenSuperior3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #3</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1 videoTrenSuperior4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #4</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1 videoTrenSuperior5'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #5</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1 videoTrenSuperior6'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #6</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1 videoTrenSuperior7'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #7</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1 videoTrenSuperior8'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #8</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1 videoTrenSuperior9'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #9</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1 videoTrenSuperior10'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Tren Superior #10</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div> */}
			</ContenedorVideos>
            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true}
						controls 
						width='100%'
						url='assets/videos/Workouts/Power/Tren Superior/20201028V01pcstwd 30Min - Jota.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>


            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/Tren Superior/20201103V04pcswd 30Min - Jota.mp4'>
					</ReactPlayer>
				

			</Modal>

			{/* <Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true} 
						controls
						width='100%'
						url=''>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true} 
						controls
						width='100%'
						url=''>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState5}
				handleState = {getModalState5}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true} 
						controls
						width='100%'
						url=''>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState6}
				handleState = {getModalState6}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true} 
						controls
						width='100%'
						url=''>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState7}
				handleState = {getModalState7}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true} 
						controls
						width='100%'
						url=''>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState8}
				handleState = {getModalState8}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true} 
						controls
						width='100%'
						url=''>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState9}
				handleState = {getModalState9}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true} 
						controls
						width='100%'
						url=''>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState10}
				handleState = {getModalState10}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true} 
						controls
						width='100%'
						url=''>
					</ReactPlayer>
					
			</Modal> */}
        </>
    )
}



const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;

