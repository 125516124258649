import React, { useState } from 'react'
import { Modal } from '../../modal/Modal'
import styled from 'styled-components';
import '../stylesNavigation/navigationPages.css'
import ReactPlayer from 'react-player';
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { TbArrowBack } from "react-icons/tb";
import { Link } from 'react-router-dom';

export const Circuito = () => {
            const [modalState1, getModalState1] = useState(false);
			const [modalState2, getModalState2] = useState(false);           const [modalState3, getModalState3] = useState(false);
            const [modalState4, getModalState4] = useState(false);
			const [modalState5, getModalState5] = useState(false);
			const [modalState6, getModalState6] = useState(false);
			const [modalState7, getModalState7] = useState(false);
			const [modalState8, getModalState8] = useState(false);
			const [modalState9, getModalState9] = useState(false);
			const [modalState10, getModalState10] = useState(false);
    return (
        <>
			<div className='titleCategory'>
				<a className='iconBcack' href='/funcional'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Cross</span>

			</div>
			<div className='titleSubcategorie'> 
				<Link to='/movilidad' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Circuito</h2>
				<Link to='/metabolico' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
			{/* <button className='btn buttonGoBack'><a href='/funcional'><MdOutlineArrowBackIosNew/></a></button> */}
            <ContenedorVideos>
				<div className='video1 videoCircuit1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #1</h3>
					<p className='titleSubcategorieTime'>21:58</p>
				</div>
				<div className='video1 videoCircuit2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #2</h3>
					<p className='titleSubcategorieTime'>21:49</p>
				</div>
				<div className='video1 videoCircuit3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #3</h3>
					<p className='titleSubcategorieTime'>33:19</p>
				</div>
				<div className='video1 videoCircuit4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #4</h3>
					<p className='titleSubcategorieTime'>22:55</p>
				</div>
				<div className='video1 videoCircuit5'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #5</h3>
					<p className='titleSubcategorieTime'>15:30</p>
				</div>
				<div className='video1 videoCircuit6'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #6</h3>
					<p className='titleSubcategorieTime'>39:53</p>
				</div>
				<div className='video1 videoCircuit7'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #7</h3>
					<p className='titleSubcategorieTime'>39:42</p>
				</div>
				<div className='video1 videoCircuit8'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #8</h3>
					<p className='titleSubcategorieTime'>31:03</p>
				</div>
				<div className='video1 videoCircuit9'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #9</h3>
					<p className='titleSubcategorieTime'>26:41</p>
				</div>
				<div className='video1 videoCircuit10'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Circuit #10</h3>
					<p className='titleSubcategorieTime'>40:13</p>
				</div>
			</ContenedorVideos>

            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						controls
						playing = {true} 
						autoplay
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20201006V04PCSTWD 15 MIN - Sandra.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>


            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20201008V01PCSTWD 15 MIN - Sandra y Jota.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer 
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20201104V04PCSWD - 30 MIN Jota.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer 
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20201111V02PASWD 15 MIN - Sandra.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState5}
				handleState = {getModalState5}
				>
					<ReactPlayer 
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20201116V04PACWD - 15 MIN Lusy.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState6}
				handleState = {getModalState6}
				>
					<ReactPlayer 
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20201125V04PAFWD 45 MIN - Lusy.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState7}
				handleState = {getModalState7}
				>
					<ReactPlayer 
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20201210V07PCFLS 45 MIN - Jota.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState8}
				handleState = {getModalState8}
				>
					<ReactPlayer 
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20210108V04PAFWD 30 MIN - Jota.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState9}
				handleState = {getModalState9}
				>
					<ReactPlayer 
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20210114V06PCSWD 30 MIN - Jota.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState10}
				handleState = {getModalState10}
				>
					<ReactPlayer 
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Circuito/20210202V03PCSLS 45 MIN - Jota.mp4'>
					</ReactPlayer>
					
			</Modal>
    </>
    )
}


const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;


