import React, { useState } from 'react'
import { Modal } from '../../modal/Modal'
import styled from 'styled-components';
import '../stylesNavigation/navigationPages.css'
import ReactPlayer from 'react-player'
import { TbArrowBack } from 'react-icons/tb';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import { Link } from 'react-router-dom';

export const Metabolico = () => {
            const [modalState1, getModalState1] = useState(false);
            const [modalState2, getModalState2] = useState(false);
            const [modalState3, getModalState3] = useState(false);
            const [modalState4, getModalState4] = useState(false);
			const [modalState5, getModalState5] = useState(false);
			const [modalState6, getModalState6] = useState(false);
			const [modalState7, getModalState7] = useState(false);
			const [modalState8, getModalState8] = useState(false);
			const [modalState9, getModalState9] = useState(false);
			const [modalState10, getModalState10] = useState(false);

    return (
        <>
        	<div className='titleCategory'>
				<a className='iconBcack' href='/funcional'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Cross</span>
			</div>
			<div className='titleSubcategorie'> 
				<Link to='/circuito' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Metabólico</h2>
				<Link to='/movilidad' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
            <ContenedorVideos>
				<div className='video1 videoMetabolico1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #1</h3>
					<p className='titleSubcategorieTime'>21:41</p>
				</div>
				<div className='video1 videoMetabolico2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #2</h3>
					<p className='titleSubcategorieTime'>21:52</p>
				</div>
				<div className='video1 videoMetabolico3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #3</h3>
					<p className='titleSubcategorieTime'>18:51</p>
				</div>
				<div className='video1 videoMetabolico4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #4</h3>
					<p className='titleSubcategorieTime'>23:39</p>
				</div>
				<div className='video1 videoMetabolico5'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #5</h3>
					<p className='titleSubcategorieTime'>28:06</p>
				</div>
				<div className='video1 videoMetabolico6'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #6</h3>
					<p className='titleSubcategorieTime'>28:30</p>
				</div>
				<div className='video1 videoMetabolico7'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #7</h3>
					<p className='titleSubcategorieTime'>27:22</p>
				</div>
				<div className='video1 videoMetabolico8'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #8</h3>
					<p className='titleSubcategorieTime'>38:37</p>
				</div> 
				<div className='video1 videoMetabolico9'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #9</h3>
					<p className='titleSubcategorieTime'>26:48</p>
				</div>
				<div className='video1 videoMetabolico10'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Metabólico #10</h3>
					<p className='titleSubcategorieTime'>38:47</p>
				</div>
			</ContenedorVideos>

            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20201007V03PCCFWD - 15 MIN Lusy y Jota.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20201008V04PCCFWD 15 MIN - Lusy.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
			<Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20201009V01PCCFWD 15 MIN - Dama.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20201030V02PCSWD 15 MIN - Dama.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
			<Modal
				state= {modalState5}
				handleState = {getModalState5}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20201106V04PCSWD 30 MIN - Dama.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
			<Modal
				state= {modalState6}
				handleState = {getModalState6}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20201211V03PCFWD - 30 MIN - Tony y Laura.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
			<Modal
				state= {modalState7}
				handleState = {getModalState7}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20201211V04PCFWD - 30 MIN - Tony.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
			<Modal
				state= {modalState8}
				handleState = {getModalState8}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20210107V07PBFWD - 45 MIN Dama.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
			<Modal
				state= {modalState9}
				handleState = {getModalState9}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20210114V05PCFWD 45 MIN - Jota.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
			<Modal
				state= {modalState10}
				handleState = {getModalState10}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Cross/Metabólico/20210122V05PVCFWD 45 MIN - Gerard.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>
        </>
    )
}

const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;