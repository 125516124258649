import React from 'react'
import './styles/Navbar.css'

export const Navbar = () => {

    return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top navbar-dark portfolio-navbar gradient">
        <div className="container-fluid">
            <a className="navbar-brand" href="/"><img className='logoNavbar' src='assets/logos/logoSinFondo.svg' alt='logoSg+'></img></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="/funcional">Cross</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/ganarMusculo">Power</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/quemagrasa">Stamina</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/cuerpoYmente">Agility</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/programasEntrenamiento">Planes de Entrenamiento</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    )
}

