import React from 'react'
import { Link } from 'react-router-dom'
import './stylesNavigation/navigationHome.css'


export const HomePage = () => {
    return (
    <>
        <section className='container section1Home'>
            <div className='row'>
                <Link to='/circuito' className='linkTrainingsSubcategory imgSubCircuit'></Link>
                <Link to='/combat' className='linkTrainingsSubcategory imgSubCombat'></Link>
                <Link to='/hiit' className='linkTrainingsSubcategory imgSubHiit'></Link>
                <Link to='/cardio' className='linkTrainingsSubcategory imgSubCardio'></Link>
                <Link to='/trenSuperior' className='linkTrainingsSubcategory imgSubTrenSuperior'></Link>
                <Link to='/gluteosYPierna' className='linkTrainingsSubcategory imgSubGluteosYPierna'></Link>
                <Link to='/absyCore' className='linkTrainingsSubcategory imgSubAbsYCore'></Link>
                <Link to='/metabolico' className='linkTrainingsSubcategory imgSubMeta'></Link>
                <Link to='/movilidad' className='linkTrainingsSubcategory imgSubMov'></Link>
                <Link to='/yoga' className='linkTrainingsSubcategory imgSubYoga'></Link>
                <Link to='/pilates' className='linkTrainingsSubcategory imgSubPilates'></Link>
                <Link to='/barre' className='linkTrainingsSubcategory imgSubBarre'></Link>
            </div>
        </section>

        <section className='section1HomeResp'>
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className='active' aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 8"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="8" aria-label="Slide 9"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="9" aria-label="Slide 10"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="10" aria-label="Slide 11"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="11" aria-label="Slide 12"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active linkTrainingsSubcategory imgSubCombat">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/combat'>
                                <button className='btn btn-light buttonCarouselHome'>Combat</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubHiit">
                        <div className="carousel-caption d-none d-md-block">
                        <Link to='/hiit'>
                                <button className='btn btn-light buttonCarouselHome'>Hiit</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubCardio">
                        <div className="carousel-caption d-none d-md-block">
                        <Link to='/cardio'>
                                <button className='btn btn-light buttonCarouselHome'>cardio</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubTrenSuperior">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/trenSuperior'>
                                <button className='btn btn-light buttonCarouselHome'>Tren Superior</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubAbsYCore">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/absYCore'>
                                <button className='btn btn-light buttonCarouselHome'>Abs & Core</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubGluteosYPierna">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/gluteosYPierna'>
                                <button className='btn btn-light buttonCarouselHome'>Glúteos y Piernas</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubCircuit">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/circuito'>
                                <button className='btn btn-light buttonCarouselHome'>Circuito</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubMeta">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/metabolico'>
                                <button className='btn btn-light buttonCarouselHome'>Metabólico</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubMov">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/movilidad'>
                                <button className='btn btn-light buttonCarouselHome'>Movilidad</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubYoga">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/yoga'>
                                <button className='btn btn-light buttonCarouselHome'>Yoga</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubPilates">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/pilates'>
                                <button className='btn btn-light buttonCarouselHome'>Pilates</button>    
                            </Link>
                        </div>
                    </div>
                    <div className="carousel-item linkTrainingsSubcategory imgSubBarre">
                        <div className="carousel-caption d-none d-md-block">
                            <Link to='/barre'>
                                <button className='btn btn-light buttonCarouselHome'>Barre</button>    
                            </Link>
                        </div>
                    </div>

                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
        <section className='section2Home'>
            <div className='linksHome1'>
                <Link className='linkImage' to='/funcional'>
                    <div className=''>
                        <div className='imgHome imgHomePage1'></div>
                    </div>
                
                </Link>
                <Link className='linkImage' to='/ganarMusculo'>
                    <div className='imgHome imgHomePage2'></div>
                </Link>
            </div>
            <div className='linksHome2'>
                <Link className='linkImage' to='/quemagrasa'>
                    <div className='imgHome imgHomePage3'></div>
                </Link>
                <Link className='linkImage' to='/cuerpoYmente'>
                    <div className='imgHome imgHomePage4'></div>
                </Link>
            </div>
        </section>

        <section className='section3Home'>
            <h3>Planes de entrenamiento</h3>
            <div className='row wrapperCardPlan'>
                <Link to='/planEntrenamiento1' className='linkTrainingsCategory'>
                    <div className="card">
                        <img src="/assets/images/programa1.jpg" alt='PE1'/>
                    </div>
                </Link>
                <Link to='/planEntrenamiento2' className='linkTrainingsCategory'>
                    <div className="card">
                        <img src="/assets/images/programa2.jpg" alt='PE2'/>
                    </div>
                </Link>
                <Link to='/planEntrenamiento3' className='linkTrainingsCategory'>
                    <div className="card">
                        <img src="/assets/images/programa3.jpg" alt='PE3' />
                    </div>
                </Link>
                <Link to='/planEntrenamiento4' className='linkTrainingsCategory'>
                    <div className="card">
                        <img src="/assets/images/programa4.jpg" alt='PE4' />
                    </div>
                </Link>
                <Link to='/planEntrenamiento5' className='linkTrainingsCategory'>
                    <div className="card">
                        <img src="/assets/images/programa5.jpg" alt='PE5' />
                    </div>
                </Link>
            </div>
        </section>
        
        
    </>


    )
}
