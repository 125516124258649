import React, { useState } from 'react'
import { Modal } from '../../modal/Modal'
import styled from 'styled-components';
import '../stylesNavigation/navigationPages.css'
import ReactPlayer from 'react-player';
import { TbArrowBack } from 'react-icons/tb';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import { Link } from 'react-router-dom';


export const Yoga = () => {
        const [modalState1, getModalState1] = useState(false);
    const [modalState2, getModalState2] = useState(false);
    const [modalState3, getModalState3] = useState(false);
    const [modalState4, getModalState4] = useState(false);
    const [modalState5, getModalState5] = useState(false);
    const [modalState6, getModalState6] = useState(false);
    const [modalState7, getModalState7] = useState(false);
    const [modalState8, getModalState8] = useState(false);
    const [modalState9, getModalState9] = useState(false);
    const [modalState10, getModalState10] = useState(false);
    return (
        <>
            <div className='titleCategory'>
				<a className='iconBcack' href='/cuerpoYMente'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Agility</span>
			</div>
			<div className='titleSubcategorie'> 
				<Link to='/pilates' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Yoga</h2>
				<Link to='/barre' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
            <ContenedorVideos>
				<div className='video1 videoYoga1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #1</h3>
					<p className='titleSubcategorieTime'>30:24</p>
				</div>
				<div className='video1 videoYoga2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #2</h3>
					<p className='titleSubcategorieTime'>27:57</p>
				</div>
				<div className='video1 videoYoga3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #3</h3>
					<p className='titleSubcategorieTime'>15:50</p>
				</div>
				<div className='video1 videoYoga4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #4</h3>
					<p className='titleSubcategorieTime'>37:37</p>
				</div>
				<div className='video1 videoYoga5'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #5</h3>
					<p className='titleSubcategorieTime'>17:54</p>
				</div>
				<div className='video1 videoYoga6'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #6</h3>
					<p className='titleSubcategorieTime'>18:04</p>
				</div>
				<div className='video1 videoYoga7'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #7</h3>
					<p className='titleSubcategorieTime'>43:40</p>
				</div>
				<div className='video1 videoYoga8'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #8</h3>
					<p className='titleSubcategorieTime'>39:12</p>
				</div>
				<div className='video1 videoYoga9'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #9</h3>
					<p className='titleSubcategorieTime'>44:03</p>
				</div>
				<div className='video1 videoYoga10'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Yoga #10</h3>
					<p className='titleSubcategorieTime'>42:18</p>
				</div>
			</ContenedorVideos>
            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20201014V03pabawd 45 Min - Jota.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>


            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20201029V03paywd 30Min - Jota.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer
						className='modalPlayer' 
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20201112V01paywd 15Min - Jota.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20201209V01paywd 30Min - Alba.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState5}
				handleState = {getModalState5}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20201209V02paywd 15Min - Alba.mp4'>
					</ReactPlayer>
					
			</Modal>
				<Modal
				state= {modalState6}
				handleState = {getModalState6}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20201209V03paywd 15Min - Alba.mp4'>
					</ReactPlayer>
					
			</Modal>
				<Modal
				state= {modalState7}
				handleState = {getModalState7}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20201212V01ywd 45Min - Alba.mp4'>
					</ReactPlayer>
					
			</Modal>
				<Modal
				state= {modalState8}
				handleState = {getModalState8}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20201222V02paywd 45Min - Chema.mp4'>
					</ReactPlayer>
					
			</Modal>
				<Modal
				state= {modalState9}
				handleState = {getModalState9}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20201222V03paywd 45Min - Chema.mp4'>
					</ReactPlayer>
					
			</Modal>
				<Modal
				state= {modalState10}
				handleState = {getModalState10}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Yoga/20210118V02paywd 45Min - Greta.mp4'>
					</ReactPlayer>
					
			</Modal>
        </>
    )
}

const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;
