import './App.css';
import { RouterApp } from './components/routes/RouterApp';


function App() {
  return (
    <>
    <div className="App">
      <RouterApp/>
    </div>
  </>
  );
}

export default App;
