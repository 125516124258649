import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md'
import { TbArrowBack } from 'react-icons/tb'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from '../../modal/Modal'

export const PlanEntrenamiento5 = () => {

    const [modalState1, getModalState1] = useState(false);
    const [modalState2, getModalState2] = useState(false); 
    const [modalState3, getModalState3] = useState(false);
    const [modalState4, getModalState4] = useState(false);
    const [modalState5, getModalState5] = useState(false);
    const [modalState6, getModalState6] = useState(false);
    const [modalState7, getModalState7] = useState(false); 
    const [modalState8, getModalState8] = useState(false);
    const [modalState9, getModalState9] = useState(false);
    const [modalState10, getModalState10] = useState(false);
    const [modalState11, getModalState11] = useState(false);
    const [modalState12, getModalState12] = useState(false);
    const [modalState13, getModalState13] = useState(false);
    const [modalState14, getModalState14] = useState(false);
    const [modalState15, getModalState15] = useState(false);
    const [modalState16, getModalState16] = useState(false);
    const [modalState17, getModalState17] = useState(false);
    const [modalState18, getModalState18] = useState(false);
    const [modalState19, getModalState19] = useState(false);
    const [modalState20, getModalState20] = useState(false);
    const [modalState21, getModalState21] = useState(false);
    const [modalState22, getModalState22] = useState(false);
    const [modalState23, getModalState23] = useState(false);
    const [modalState24, getModalState24] = useState(false);
    const [modalState25, getModalState25] = useState(false);
    const [modalState26, getModalState26] = useState(false);
    const [modalState27, getModalState27] = useState(false);
    const [modalState28, getModalState28] = useState(false);
    const [modalState29, getModalState29] = useState(false);
    const [modalState30, getModalState30] = useState(false);
    const [modalState31, getModalState31] = useState(false);
    const [modalState32, getModalState32] = useState(false);
    const [modalState33, getModalState33] = useState(false);
    const [modalState34, getModalState34] = useState(false);
    const [modalState35, getModalState35] = useState(false);
    const [modalState36, getModalState36] = useState(false);
    const [modalState37, getModalState37] = useState(false);
    const [modalState38, getModalState38] = useState(false);
    const [modalState39, getModalState39] = useState(false);
    const [modalState40, getModalState40] = useState(false);
    const [modalState41, getModalState41] = useState(false);
    const [modalState42, getModalState42] = useState(false);
    const [modalState43, getModalState43] = useState(false);
    const [modalState44, getModalState44] = useState(false);
    const [modalState45, getModalState45] = useState(false);
    const [modalState46, getModalState46] = useState(false);
    const [modalState47, getModalState47] = useState(false);
    const [modalState48, getModalState48] = useState(false);
    return (
        <>
            <div>
                <div className='titleCategory'>
                    <a className='iconBcack' href='/programasEntrenamiento'><TbArrowBack/></a>
                    <span className='titleCategorie'> Categoría: Plan de Entrenamiento</span>
			    </div>
                    <div className='wrapperCategorie'>
                        <Link to='/planEntrenamiento4' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
                        <h2 className='titlePlan'>Transforma tu cuerpo en 12 semanas</h2>
                        <Link to='/planEntrenamiento1' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
                    </div>
                    <p className='paragraphCategorie'>Plan de entrenamiento específico de 4 semanas para ponerse en forma después del embarazo.</p>
            </div>
                <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header># Semana 1</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma1'>
                                <img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #1</h3>
                                <p className='titleSubcategorieTime'>31:13</p>
                            </div>
                            <div className='video1 videoTransforma2'>
                                <img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #2</h3>
                                <p className='titleSubcategorieTime'>30:16</p>
                            </div>
                            <div className='video1 videoTransforma3'>
                                <img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #3</h3>
                                <p className='titleSubcategorieTime'>31:00</p>
                            </div>
                            <div className='video1 videoTransforma4'>
                                <img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #4</h3>
                                <p className='titleSubcategorieTime'>30:07</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState1}
                            handleState = {getModalState1}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width= '100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 1.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState1(!modalState1)}> Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState2}
                            handleState = {getModalState2}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 2.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState2(!modalState2)}> Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState3}
                            handleState = {getModalState3}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 3.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState3(!modalState3)}> Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState4}
                            handleState = {getModalState4}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 4.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState4(!modalState4)}> Terminar</Boton>
                        </Modal>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header># Semana 2</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma5'>
                                <img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #5</h3>
                                <p className='titleSubcategorieTime'>30:08</p>
                            </div>
                            <div className='video1 videoTransforma6'>
                                <img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #6</h3>
                                <p className='titleSubcategorieTime'>30:03</p>
                            </div>
                            <div className='video1 videoTransforma7'>
                                <img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #7</h3>
                                <p className='titleSubcategorieTime'>30:06</p>
                            </div>
                            <div className='video1 videoTransforma8'>
                                <img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #8</h3>
                                <p className='titleSubcategorieTime'>30:23</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState5}
                            handleState = {getModalState5}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 5.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState5(!modalState5)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState6}
                            handleState = {getModalState6}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 6.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState6(!modalState6)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState7}
                            handleState = {getModalState7}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 7.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState7(!modalState7)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState8}
                            handleState = {getModalState8}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 8.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState8(!modalState8)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header># Semana 3</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma9'>
                                <img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #9</h3>
                                <p className='titleSubcategorieTime'>36:13</p>
                            </div>
                            <div className='video1 videoTransforma10'>
                                <img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #10</h3>
                                <p className='titleSubcategorieTime'>35:57</p>
                            </div>
                            <div className='video1 videoTransforma11'>
                                <img onClick={() => getModalState11(!modalState11)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #11</h3>
                                <p className='titleSubcategorieTime'>35:56</p>
                            </div>
                            <div className='video1 videoTransforma12'>
                                <img onClick={() => getModalState12(!modalState12)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #12</h3>
                                <p className='titleSubcategorieTime'>35:39</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState9}
                            handleState = {getModalState9}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 9.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState9(!modalState9)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState10}
                            handleState = {getModalState10}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 10.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState10(!modalState10)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState11}
                            handleState = {getModalState11}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 11.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState11(!modalState11)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState12}
                            handleState = {getModalState12}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 12.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState12(!modalState12)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header># Semana 4</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma13'>
                                <img onClick={() => getModalState13(!modalState13)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #13</h3>
                                <p className='titleSubcategorieTime'>36:36</p>
                            </div>
                            <div className='video1 videoTransforma14'>
                                <img onClick={() => getModalState14(!modalState14)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #14</h3>
                                <p className='titleSubcategorieTime'>35:25</p>
                            </div>
                            <div className='video1 videoTransforma15'>
                                <img onClick={() => getModalState15(!modalState15)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #15</h3>
                                <p className='titleSubcategorieTime'>35:39</p>
                            </div>
                            <div className='video1 videoTransforma16'>
                                <img onClick={() => getModalState16(!modalState16)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #16</h3>
                                <p className='titleSubcategorieTime'>35:45</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState13}
                            handleState = {getModalState13}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 13.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState13(!modalState13)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState14}
                            handleState = {getModalState14}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 14.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState14(!modalState14)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState15}
                            handleState = {getModalState15}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 15.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState15(!modalState15)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState16}
                            handleState = {getModalState16}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 16.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState16(!modalState16)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header># Semana 5</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videotransforma17'>
                                <img onClick={() => getModalState17(!modalState17)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #17</h3>
                                <p className='titleSubcategorieTime'>41:29</p>
                            </div>
                            <div className='video1 videotransforma18'>
                                <img onClick={() => getModalState18(!modalState18)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #18</h3>
                                <p className='titleSubcategorieTime'>41:08</p>
                            </div>
                            <div className='video1 videotransforma19'>
                                <img onClick={() => getModalState19(!modalState19)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #19</h3>
                                <p className='titleSubcategorieTime'>41:31</p>
                            </div>
                            <div className='video1 videotransforma20'>
                                <img onClick={() => getModalState20(!modalState20)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #20</h3>
                                <p className='titleSubcategorieTime'>41:23</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState17}
                            handleState = {getModalState17}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 17.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState17(!modalState17)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState18}
                            handleState = {getModalState18}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 18.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState18(!modalState18)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState19}
                            handleState = {getModalState19}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 19.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState19(!modalState19)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState20}
                            handleState = {getModalState20}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 20.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState20(!modalState20)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header># Semana 6</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videotransforma21'>
                                <img onClick={() => getModalState21(!modalState21)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #21</h3>
                                <p className='titleSubcategorieTime'>41:17</p>
                            </div>
                            <div className='video1 videotransforma22'>
                                <img onClick={() => getModalState22(!modalState22)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #22</h3>
                                <p className='titleSubcategorieTime'>40:56</p>
                            </div>
                            <div className='video1 videotransforma23'>
                                <img onClick={() => getModalState23(!modalState23)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #23</h3>
                                <p className='titleSubcategorieTime'>41:18</p>
                            </div>
                            <div className='video1 videotransforma24'>
                                <img onClick={() => getModalState24(!modalState24)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #24</h3>
                                <p className='titleSubcategorieTime'>41:12</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState21}
                            handleState = {getModalState21}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 21.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState21(!modalState21)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState22}
                            handleState = {getModalState22}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 22.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState22(!modalState22)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState23}
                            handleState = {getModalState23}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 23.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState23(!modalState23)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState24}
                            handleState = {getModalState24}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 24.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState24(!modalState24)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header># Semana 7</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma25'>
                                <img onClick={() => getModalState25(!modalState25)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #25</h3>
                                <p className='titleSubcategorieTime'>41:16</p>
                            </div>
                            <div className='video1 videoTransforma26'>
                                <img onClick={() => getModalState26(!modalState26)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #26</h3>
                                <p className='titleSubcategorieTime'>41:13</p>
                            </div>
                            <div className='video1 videoTransforma27'>
                                <img onClick={() => getModalState27(!modalState27)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #27</h3>
                                <p className='titleSubcategorieTime'>41:00</p>
                            </div>
                            <div className='video1 videoTransforma28'>
                                <img onClick={() => getModalState28(!modalState28)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #28</h3>
                                <p className='titleSubcategorieTime'>41:13</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState25}
                            handleState = {getModalState25}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 25.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState25(!modalState25)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState26}
                            handleState = {getModalState26}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 26.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState26(!modalState26)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState27}
                            handleState = {getModalState27}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 27.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState27(!modalState27)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState28}
                            handleState = {getModalState28}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 28.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState28(!modalState28)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header># Semana 8</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma29'>
                                <img onClick={() => getModalState29(!modalState29)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #29</h3>
                                <p className='titleSubcategorieTime'>40:54</p>
                            </div>
                            <div className='video1 videoTransforma30'>
                                <img onClick={() => getModalState30(!modalState30)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #30</h3>
                                <p className='titleSubcategorieTime'>40:48</p>
                            </div>
                            <div className='video1 videoTransforma31'>
                                <img onClick={() => getModalState31(!modalState31)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #31</h3>
                                <p className='titleSubcategorieTime'>40:56</p>
                            </div>
                            <div className='video1 videoTransforma32'>
                                <img onClick={() => getModalState32(!modalState32)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #32</h3>
                                <p className='titleSubcategorieTime'>41:07</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState29}
                            handleState = {getModalState29}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 29.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState29(!modalState29)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState30}
                            handleState = {getModalState30}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 30.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState30(!modalState30)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState31}
                            handleState = {getModalState31}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 31.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState31(!modalState31)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState32}
                            handleState = {getModalState32}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 32.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState32(!modalState32)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header># Semana 9</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma33'>
                                <img onClick={() => getModalState33(!modalState33)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #33</h3>
                                <p className='titleSubcategorieTime'>44:48</p>
                            </div>
                            <div className='video1 videoTransforma34'>
                                <img onClick={() => getModalState34(!modalState34)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #34</h3>
                                <p className='titleSubcategorieTime'>46:15</p>
                            </div>
                            <div className='video1 videoTransforma35'>
                                <img onClick={() => getModalState35(!modalState35)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #35</h3>
                                <p className='titleSubcategorieTime'>46:14</p>
                            </div>
                            <div className='video1 videoTransforma36'>
                                <img onClick={() => getModalState36(!modalState36)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #36</h3>
                                <p className='titleSubcategorieTime'>46:41</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState33}
                            handleState = {getModalState33}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 33.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState33(!modalState33)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState34}
                            handleState = {getModalState34}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 34.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState34(!modalState34)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState35}
                            handleState = {getModalState35}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 35.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState35(!modalState35)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState36}
                            handleState = {getModalState36}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 36.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState36(!modalState36)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header># Semana 10</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma37'>
                                <img onClick={() => getModalState37(!modalState37)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #37</h3>
                                <p className='titleSubcategorieTime'>46:28</p>
                            </div>
                            <div className='video1 videoTransforma38'>
                                <img onClick={() => getModalState38(!modalState38)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #38</h3>
                                <p className='titleSubcategorieTime'>46:24</p>
                            </div>
                            <div className='video1 videoTransforma39'>
                                <img onClick={() => getModalState39(!modalState39)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #39</h3>
                                <p className='titleSubcategorieTime'>46:49</p>
                            </div>
                            <div className='video1 videoTransforma40'>
                                <img onClick={() => getModalState40(!modalState40)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #40</h3>
                                <p className='titleSubcategorieTime'>46:18</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState37}
                            handleState = {getModalState37}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 37.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState37(!modalState37)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState38}
                            handleState = {getModalState38}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 38.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState38(!modalState38)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState39}
                            handleState = {getModalState39}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 39.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState39(!modalState39)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState40}
                            handleState = {getModalState40}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 40.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState40(!modalState40)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header># Semana 11</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma41'>
                                <img onClick={() => getModalState41(!modalState41)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #41</h3>
                                <p className='titleSubcategorieTime'>46:32</p>
                            </div>
                            <div className='video1 videoTransforma42'>
                                <img onClick={() => getModalState42(!modalState42)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #42</h3>
                                <p className='titleSubcategorieTime'>45:59</p>
                            </div>
                            <div className='video1 videoTransforma43'>
                                <img onClick={() => getModalState43(!modalState43)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #43</h3>
                                <p className='titleSubcategorieTime'>45:48</p>
                            </div>
                            <div className='video1 videoTransforma44'>
                                <img onClick={() => getModalState44(!modalState44)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #44</h3>
                                <p className='titleSubcategorieTime'>46:11</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState41}
                            handleState = {getModalState41}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 41.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState41(!modalState41)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState42}
                            handleState = {getModalState42}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 42.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState42(!modalState42)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState43}
                            handleState = {getModalState43}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 43.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState43(!modalState43)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState44}
                            handleState = {getModalState44}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 44.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState44(!modalState44)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                    <Accordion.Header># Semana 12</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTransforma45'>
                                <img onClick={() => getModalState45(!modalState45)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #45</h3>
                                <p className='titleSubcategorieTime'>46:21</p>
                            </div>
                            <div className='video1 videoTransforma46'>
                                <img onClick={() => getModalState46(!modalState46)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #46</h3>
                                <p className='titleSubcategorieTime'>45:48</p>
                            </div>
                            <div className='video1 videoTransforma47'>
                                <img onClick={() => getModalState47(!modalState47)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #47</h3>
                                <p className='titleSubcategorieTime'>45:39</p>
                            </div>
                            <div className='video1 videoTransforma48'>
                                <img onClick={() => getModalState48(!modalState48)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #48 + Mensaje</h3>
                                <p className='titleSubcategorieTime'>46:03</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState45}
                            handleState = {getModalState45}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 45.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState45(!modalState45)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState46}
                            handleState = {getModalState46}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 46.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState46(!modalState46)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState47}
                            handleState = {getModalState47}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 47.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState47(!modalState47)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState48}
                            handleState = {getModalState48}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/transformaTuCuerpo/Transforma tu cuerpo en 12 semanas CAP 48.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState48(!modalState48)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

const ContenedorVideos = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;

const Boton = styled.button`
    color: white;
    margin: 10px;
    padding: 5px;
    border-radius: 6px;
    border: none;
    background-color: #048870;
`

