import React from 'react'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md'
import { Link } from 'react-router-dom'
import '../navigation/stylesNavigation/navigationCategories.css'


export const CuerpoYMente = () => {
    return (
        <div>
            <div className='wrapperCategorie'>
                <Link to='/ganarMusculo' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
                <img className='logoCategory' src='/assets/images/Agility.png' alt='agilityLogo'></img>
                <Link to='/funcional' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
            </div>
            <p className='paragraphCategorie'>En Agility te ofrecemos 3 programas para conectar cuerpo y mente y trabajar mediante entrenamientos de Yoga, Pilates y Barre.</p>
            <div className='categoriesWrapper'>
                <Link className='linkSubcategorie subcategorie10' to="/yoga"></Link>
                <Link className='linkSubcategorie subcategorie11' to="/pilates"></Link>
                <Link className='linkSubcategorie subcategorie12' to="/barre"></Link>
            </div>
        </div>
    )
}
