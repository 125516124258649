import React from 'react'
import '../navigation/stylesNavigation/navigationCategories.css'
import { Link } from 'react-router-dom'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md'

export const Quemagrasa = () => {
    return (
        <div>
            <div className='wrapperCategorie'>
                <Link to='/funcional' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
                <img className='logoCategory' src='/assets/images/Stamina.png' alt='staminaLogo'></img>
                <Link to='/ganarMusculo' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
            </div>
            <p className='paragraphCategorie'>En Stamina te toca sudar un poco con los entrenamientos por intervalos, cardio y combat.</p>
            <div className='categoriesWrapper'>
                <Link className='linkSubcategorie subcategorie7' to="/combat"></Link>
                <Link className='linkSubcategorie subcategorie8' to="/hiit"></Link>
                <Link className='linkSubcategorie subcategorie9' to="/cardio"></Link>
            </div>
        </div>
    )
}
