import React from 'react'
import '../navigation/stylesNavigation/navigationCategories.css'
import { Link } from 'react-router-dom'



export const PlanesDeEntrenamiento = () => {
    return (
        <div>
            <div className='wrapperCategorie'>
                {/* <Link to='/funcional' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link> */}
                <h1 className='titlePlan'>Planes de Entrenamiento</h1>
                {/* <Link to='/ganarMusculo' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link> */}
            </div>
            <div className='categoriesWrapper'>
                <Link className='linkSubcategorie trainingPlan1' to="/planEntrenamiento1"></Link>
                <Link className='linkSubcategorie trainingPlan2' to="/planEntrenamiento2"></Link>
                <Link className='linkSubcategorie trainingPlan3' to="/planEntrenamiento3"></Link>
                <Link className='linkSubcategorie trainingPlan4' to="/planEntrenamiento4"></Link>
                <Link className='linkSubcategorie trainingPlan5' to="/planEntrenamiento5"></Link>
            </div>
        </div>
    )
}

