import React from 'react'
import '../navigation/stylesNavigation/navigationCategories.css'
import { Link } from 'react-router-dom'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md'

export const GanarMusculo = () => {
    return (
        <div>
            <div className='wrapperCategorie'>
                <Link to='/quemagrasa' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
                <img className='logoCategory' src='/assets/images/Power.png' alt='powerLogo'></img>
                <Link to='/cuerpoYmente' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
            </div>
            <p className='paragraphCategorie'>Aquí tienes 3 programas de entrenamientos para enfocar tus objetivos basados en la mejora de la masa muscular.</p>
            <div className='categoriesWrapper'>
                <Link className='linkSubcategorie subcategorie4' to="/absYCore"></Link>
                <Link className='linkSubcategorie subcategorie5' to="/trenSuperior"></Link>
                <Link className='linkSubcategorie subcategorie6' to="/gluteosYPierna"></Link>
            </div>
        </div>
    
    )
}
