import React, { useState } from 'react'
import { Modal } from '../../modal/Modal'
import styled from 'styled-components';
import '../stylesNavigation/navigationPages.css'
import ReactPlayer from 'react-player';
import { TbArrowBack } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';

export const Cardio = () => {
    const [modalState1, getModalState1] = useState(false);
    const [modalState2, getModalState2] = useState(false);
    const [modalState3, getModalState3] = useState(false);
    const [modalState4, getModalState4] = useState(false);
    const [modalState5, getModalState5] = useState(false);
    const [modalState6, getModalState6] = useState(false);
    const [modalState7, getModalState7] = useState(false);
    const [modalState8, getModalState8] = useState(false);
    const [modalState9, getModalState9] = useState(false);
    const [modalState10, getModalState10] = useState(false);
    return (
    
        <>
            <div className='titleCategory'>
				<a className='iconBcack' href='/quemagrasa'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Stamina</span>
			</div>
			<div className='titleSubcategorie'> 
				<Link to='/hiit' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Cardio</h2>
				<Link to='/combat' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
            <ContenedorVideos>
				<div className='video1 videoCardio1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #1</h3>
					<p className='titleSubcategorieTime'>11:51</p>
				</div>
				<div className='video1 videoCardio2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #2</h3>
					<p className='titleSubcategorieTime'>12:12</p>
				</div>
				<div className='video1 videoCardio3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #3</h3>
					<p className='titleSubcategorieTime'>20:17</p>
				</div>
				<div className='video1 videoCardio4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #4</h3>
					<p className='titleSubcategorieTime'>25:09</p>
				</div>
				<div className='video1 videoCardio5'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #5</h3>
					<p className='titleSubcategorieTime'>22:53</p>
				</div>
				<div className='video1 videoCardio6'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #6</h3>
					<p className='titleSubcategorieTime'>22:39</p>
				</div>
				<div className='video1 videoCardio7'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #7</h3>
					<p className='titleSubcategorieTime'>31:31</p>
				</div>
				<div className='video1 videoCardio8'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #8</h3>
					<p className='titleSubcategorieTime'>35:45</p>
				</div>
				<div className='video1 videoCardio9'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #9</h3>
					<p className='titleSubcategorieTime'>35:21</p>
				</div>
				<div className='video1 videoCardio10'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Cardio #10</h3>
					<p className='titleSubcategorieTime'>22:33</p>
				</div>
			</ContenedorVideos>
            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true}
						controls 
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20201001V02PBCAWD - 15 Sandra.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>


            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20201002V02PBCAWD - 30 Dama.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20201117V04PBCWD 15 Jota.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20201120V06PBCWD - 30 Dama.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState5}
				handleState = {getModalState5}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20201211V05PBCWD - 30 Tony.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState6}
				handleState = {getModalState6}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20201211V06PBCWD - 30 Tony.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState7}
				handleState = {getModalState7}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20210105V05PBCWD - 30 Jota.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState8}
				handleState = {getModalState8}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20210105V06PBCWD - 30 Dama.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState9}
				handleState = {getModalState9}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20210112V06PBCWDC 30 Dama.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState10}
				handleState = {getModalState10}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Cardio/20210112V07PBCWD 45 Dama.mp4'>
					</ReactPlayer>
					
			</Modal>

        </>
    )
}


const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;