import React, { useState } from 'react'
import { Modal } from '../../modal/Modal'
import styled from 'styled-components';
import '../stylesNavigation/navigationPages.css'
import ReactPlayer from 'react-player';
import { TbArrowBack } from 'react-icons/tb';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import { Link } from 'react-router-dom';


export const Barre = () => {
    const [modalState1, getModalState1] = useState(false);
    const [modalState2, getModalState2] = useState(false);
    const [modalState3, getModalState3] = useState(false);
    const [modalState4, getModalState4] = useState(false);
    // const [modalState5, getModalState5] = useState(false);
    // const [modalState6, getModalState6] = useState(false);
    // const [modalState7, getModalState7] = useState(false);
    // const [modalState8, getModalState8] = useState(false);
    // const [modalState9, getModalState9] = useState(false);
    // const [modalState10, getModalState10] = useState(false);
    return (
        <>
            <div className='titleCategory'>
				<a className='iconBcack' href='/cuerpoYMente'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Agility</span>
			</div>
			<div className='titleSubcategorie'> 
				<Link to='/yoga' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Barre</h2>
				<Link to='/pilates' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
            <ContenedorVideos>
				<div className='video1 videoBarre1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #1</h3>
					<p className='titleSubcategorieTime'>34:12</p>
				</div>
				<div className='video1 videoBarre2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #2</h3>
					<p className='titleSubcategorieTime'>30:38</p>
				</div>
				<div className='video1 videoBarre3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #3</h3>
					<p className='titleSubcategorieTime'>31:33</p>
				</div>
				<div className='video1 videoBarre4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #4</h3>
					<p className='titleSubcategorieTime'>33:15</p>
				</div>
				{/* <div className='video1'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #5</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div> 
				<div className='video1'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #6</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #7</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #8</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #9</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Barre #10</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>*/}
			</ContenedorVideos>
            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Barre/20201203V04bls 30 Min - Jota.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>


            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Barre/20201217V03bls 30 Min - Jota.mp4'>
					</ReactPlayer>
			</Modal>

			<Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer 
						controls
						width='100%'
						url='assets/videos/Workouts/Agility/Barre/20210119V05pabwd 30 Min - Jota.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer 
						controls
						width='100%'
						url='assets/videos/Workouts/Agility/Barre/20210212V03pbbls 30 Min - Jota.mp4'>
					</ReactPlayer>
					
			</Modal>

        </>
    )
}


const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;