import React from 'react'
import './styles/Footer.css'

export const Footer = () => {
    return (
        <div className='footerWrapper'>
            <img src='/assets/images/icon_SY.png' alt='iconSy'></img>
            <p className='titleFooter'>Synergym+ &copy; 2023, todos los derechos reservados.</p>
        </div>
    )
}
