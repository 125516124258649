import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Navbar } from '../Navbar'
import { HomePage } from '../navigation/HomePage'
import { Circuito } from '../navigation/funcional/Circuito'
import { Combat } from '../navigation/quemagrasa/Combat'
import { Hiit } from '../navigation/quemagrasa/Hiit'
import { GluteosyPierna } from '../navigation/ganarMusculo/GluteosyPierna'
import { AbsyCore } from '../navigation/ganarMusculo/AbsyCore'
import { Metabolico } from '../navigation/funcional/Metabolico'
import { Movilidad } from '../navigation/funcional/Movilidad'
import { Yoga } from '../navigation/cuerpoYmente/Yoga'
import { Pilates } from '../navigation/cuerpoYmente/Pilates'
import { Barre } from '../navigation/cuerpoYmente/Barre'
import { GanarMusculo } from '../navigation/GanarMusculo'
import { Quemagrasa } from '../navigation/Quemagrasa'
import { CuerpoYMente } from '../navigation/CuerpoYMente'
import { Funcional } from '../navigation/Funcional'
import { PlanEntrenamiento1 } from '../navigation/planesDeEntrenamiento/PlanEntrenamiento1'
import { PlanEntrenamiento2 } from '../navigation/planesDeEntrenamiento/PlanEntrenamiento2'
import { PlanEntrenamiento3 } from '../navigation/planesDeEntrenamiento/PlanEntrenamiento3'
import { PlanEntrenamiento4 } from '../navigation/planesDeEntrenamiento/PlanEntrenamiento4'
import { PlanEntrenamiento5 } from '../navigation/planesDeEntrenamiento/PlanEntrenamiento5'
import { Footer } from '../Footer'
import { TrenSuperior } from '../navigation/ganarMusculo/TrenSuperior'
import { Cardio } from '../navigation/quemagrasa/Cardio'
import { PlanesDeEntrenamiento } from '../navigation/PlanesDeEntrenamiento'


export const RouterApp = () => {
    return (
        <BrowserRouter>

            <Navbar/>
            <Routes>
                {/* Categories */}

                <Route path='/ganarMusculo' element={ <GanarMusculo/> }/>;
                <Route path='/quemagrasa' element={ <Quemagrasa/> }/>;
                <Route path='/cuerpoYmente' element={ <CuerpoYMente/> }/>;
                <Route path='/funcional' element={ <Funcional/> }/>;
                <Route path='/programasEntrenamiento' element={<PlanesDeEntrenamiento/>} />


                {/* SubCategories */}


                <Route path='/' element= { <HomePage/> }/>;
                <Route path='/circuito' element= { <Circuito/> }/>;
                <Route path='/combat' element= { <Combat/> }/>;
                <Route path='/hiit' element= { <Hiit/> }/>;
                <Route path='/trenSuperior' element={ <TrenSuperior/> }/>;
                <Route path='/cardio' element= { <Cardio/> }/>;
                <Route path='/gluteosYPierna' element= { <GluteosyPierna/> }/>;
                <Route path='/absYCore' element= { <AbsyCore/> }/>;
                <Route path='/metabolico' element= { <Metabolico/> }/>;
                <Route path='/movilidad' element= { <Movilidad/> }/>;
                <Route path='/yoga' element= { <Yoga/> }/>;
                <Route path='/pilates' element= { <Pilates/> }/>;
                <Route path='/barre' element= { <Barre/> }/>;

                {/* Planes de Entrenamiento */}
                
                <Route path='/planEntrenamiento1' element={ <PlanEntrenamiento1/> }/>
                <Route path='/planEntrenamiento2' element={ <PlanEntrenamiento2/> }/>
                <Route path='/planEntrenamiento3' element={ <PlanEntrenamiento3/> }/>
                <Route path='/planEntrenamiento4' element={ <PlanEntrenamiento4/> }/>
                <Route path='/planEntrenamiento5' element={ <PlanEntrenamiento5/> }/>
                
                

            </Routes>
            <Footer/>
        </BrowserRouter>
    )
}
