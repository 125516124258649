import React, { useState } from 'react'
import { Modal } from '../../modal/Modal'
import styled from 'styled-components';
import '../stylesNavigation/navigationPages.css'
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { TbArrowBack } from 'react-icons/tb';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';


export const AbsyCore = () => {
    const [modalState1, getModalState1] = useState(false);
    const [modalState2, getModalState2] = useState(false);
    const [modalState3, getModalState3] = useState(false);
    const [modalState4, getModalState4] = useState(false);
    const [modalState5, getModalState5] = useState(false);
    const [modalState6, getModalState6] = useState(false);
    const [modalState7, getModalState7] = useState(false);
    const [modalState8, getModalState8] = useState(false);
    const [modalState9, getModalState9] = useState(false);
    const [modalState10, getModalState10] = useState(false);
    return (
        <>
			<div className='titleCategory'>
				<a className='iconBcack' href='/ganarMusculo'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Power</span>

			</div>
			<div className='titleSubcategorie'> 
				<Link to='/trenSuperior' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Abs & Core</h2>
				<Link to='/gluteosYPierna' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
            <ContenedorVideos>
				<div className='video1 videoAbsCore1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #1</h3>
					<p className='titleSubcategorieTime'>10:25</p>
				</div>
				<div className='video1 videoAbsCore2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #2</h3>
					<p className='titleSubcategorieTime'>20:47</p>
				</div>
				<div className='video1 videoAbsCore3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #3</h3>
					<p className='titleSubcategorieTime'>10:31</p>
				</div>
				<div className='video1 videoAbsCore4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #4</h3>
					<p className='titleSubcategorieTime'>10:25</p>
				</div>
				<div className='video1 videoAbsCore5'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #5</h3>
					<p className='titleSubcategorieTime'>10:28</p>
				</div>
				<div className='video1 videoAbsCore6'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #6</h3>
					<p className='titleSubcategorieTime'>12:09</p>
				</div>
				<div className='video1 videoAbsCore7'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #7</h3>
					<p className='titleSubcategorieTime'>10:22</p>
				</div>
				<div className='video1 videoAbsCore8'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #8</h3>
					<p className='titleSubcategorieTime'>11:26</p>
				</div>
				<div className='video1 videoAbsCore9'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #9</h3>
					<p className='titleSubcategorieTime'>20:01</p>
				</div>
				<div className='video1 videoAbsCore10'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Abs & Core #10</h3>
					<p className='titleSubcategorieTime'>25:27</p>
				</div>
			</ContenedorVideos>
            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20201002V01pacawd 15Min - Dama.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>


            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20201029V04pacswd 15Min - Sandra.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20201104V05pcswd 15Min - Lusy.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20201104V06pcswd 15Min - Lusy.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState5}
				handleState = {getModalState5}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20201105V05pcswd 15Min - Lusy.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState6}
				handleState = {getModalState6}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20201105V06pcswd 15Min - Lusy.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState7}
				handleState = {getModalState7}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20201106V05pcswd 15Min - Jota.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState8}
				handleState = {getModalState8}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20201110V06paswd 15Min - Sandra.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState9}
				handleState = {getModalState9}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20210120V01paswd 15Min - Jota.mp4'>
					</ReactPlayer>
					
			</Modal>
			<Modal
				state= {modalState10}
				handleState = {getModalState10}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Power/ABS y Core/20210308V05pasls 30Min - Jota.mp4'>
					</ReactPlayer>
					
			</Modal>

        </>
    )
}


const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;