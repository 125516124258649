import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md'
import { TbArrowBack } from 'react-icons/tb'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from '../../modal/Modal'

export const PlanEntrenamiento3 = () => {
    const [modalState1, getModalState1] = useState(false)
    const [modalState2, getModalState2] = useState(false)
    const [modalState3, getModalState3] = useState(false)
    const [modalState4, getModalState4] = useState(false)
    const [modalState5, getModalState5] = useState(false)
    const [modalState6, getModalState6] = useState(false)
    const [modalState7, getModalState7] = useState(false)
    const [modalState8, getModalState8] = useState(false)
    const [modalState9, getModalState9] = useState(false)
    const [modalState10, getModalState10] = useState(false)
    const [modalState11, getModalState11] = useState(false)
    const [modalState12, getModalState12] = useState(false)
    const [modalState13, getModalState13] = useState(false)
    const [modalState14, getModalState14] = useState(false)
    const [modalState15, getModalState15] = useState(false)
    const [modalState16, getModalState16] = useState(false)
    const [modalState17, getModalState17] = useState(false)
    const [modalState18, getModalState18] = useState(false)
    const [modalState19, getModalState19] = useState(false)
    const [modalState20, getModalState20] = useState(false)
    const [modalState21, getModalState21] = useState(false)
    const [modalState22, getModalState22] = useState(false)
    const [modalState23, getModalState23] = useState(false)
    const [modalState24, getModalState24] = useState(false)
    const [modalState25, getModalState25] = useState(false)
    return (
        <>
        <div>
            <div className='titleCategory'>
				<a className='iconBcack' href='/programasEntrenamiento'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Plan de Entrenamiento</span>
			</div>
                <div className='wrapperCategorie'>
                    <Link to='/planEntrenamiento2' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
                    <h2 className='titlePlan'>GAP-25</h2>
                    <Link to='/planEntrenamiento4' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
                </div>
                <p className='paragraphCategorie'>Plan de entrenamiento intermedio con enfoque en glúteos, abdominales y piernas.</p>
        </div>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header># Semana 1</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoGap25-1'>
                                <img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #1</h3>
                                <p className='titleSubcategorieTime'>25:46</p>
                            </div>
                            <div className='video1 videoGap25-2'>
                                <img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #2</h3>
                                <p className='titleSubcategorieTime'>09:17</p>
                            </div>
                            <div className='video1 videoGap25-3'>
                                <img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #3</h3>
                                <p className='titleSubcategorieTime'>24:23</p>
                            </div>
                            <div className='video1 videoGap25-4'>
                                <img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #4</h3>
                                <p className='titleSubcategorieTime'>08:31</p>
                            </div>
                            <div className='video1 videoGap25-5'>
                                <img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #5</h3>
                                <p className='titleSubcategorieTime'>21:34</p>
                            </div>
                            <div className='video1 videoGap25-6'>
                                <img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #6</h3>
                                <p className='titleSubcategorieTime'>10:05</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState1}
                            handleState = {getModalState1}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width= '100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 1.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState1(!modalState1)}> Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState2}
                            handleState = {getModalState2}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 2.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState2(!modalState2)}> Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState3}
                            handleState = {getModalState3}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 3.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState3(!modalState3)}> Terminar</Boton>
                        </Modal>
                         <Modal
                            state= {modalState4}
                            handleState = {getModalState4}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 4.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState4(!modalState4)}> Terminar</Boton>
                        </Modal>
                         <Modal
                            state= {modalState5}
                            handleState = {getModalState5}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 5.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState5(!modalState5)}> Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState6}
                            handleState = {getModalState6}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 6.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState6(!modalState6)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header># Semana 2</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            
                            <div className='video1 videoGap25-7'>
                                <img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #7</h3>
                                <p className='titleSubcategorieTime'>27:35</p>
                            </div>
                            <div className='video1 videoGap25-8'>
                                <img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #8</h3>
                                <p className='titleSubcategorieTime'>10:25</p>
                            </div>
                            <div className='video1 videoGap25-9'>
                                <img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #9</h3>
                                <p className='titleSubcategorieTime'>26:49</p>
                            </div>
                            <div className='video1 videoGap25-10'>
                                <img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #10</h3>
                                <p className='titleSubcategorieTime'>11:12</p>
                            </div>
                            <div className='video1 videoGap25-11'>
                                <img onClick={() => getModalState11(!modalState11)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #11</h3>
                                <p className='titleSubcategorieTime'>23:30</p>
                            </div>
                            <div className='video1 videoGap25-12'>
                                <img onClick={() => getModalState12(!modalState12)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #12</h3>
                                <p className='titleSubcategorieTime'>10:38</p>
                            </div>
                        </ContenedorVideos>
                       
                        <Modal
                            state= {modalState7}
                            handleState = {getModalState7}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 7.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState7(!modalState7)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState8}
                            handleState = {getModalState8}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 8.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState8(!modalState8)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState9}
                            handleState = {getModalState9}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 9.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState9(!modalState9)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState10}
                            handleState = {getModalState10}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 10.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState10(!modalState10)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState11}
                            handleState = {getModalState11}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 11.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState11(!modalState11)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState12}
                            handleState = {getModalState12}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 12.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState12(!modalState12)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header># Semana 3</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            
                            <div className='video1 videoGap25-13'>
                                <img onClick={() => getModalState13(!modalState13)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #13</h3>
                                <p className='titleSubcategorieTime'>25:28</p>
                            </div>
                            <div className='video1 videoGap25-14'>
                                <img onClick={() => getModalState14(!modalState14)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #14</h3>
                                <p className='titleSubcategorieTime'>09:23</p>
                            </div>
                            <div className='video1 videoGap25-15'>
                                <img onClick={() => getModalState15(!modalState15)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #15</h3>
                                <p className='titleSubcategorieTime'>21:12</p>
                            </div><div className='video1 videoGap25-16'>
                                <img onClick={() => getModalState16(!modalState16)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #16</h3>
                                <p className='titleSubcategorieTime'>10:06</p>
                            </div>
                            <div className='video1 videoGap25-17'>
                                <img onClick={() => getModalState17(!modalState17)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #17</h3>
                                <p className='titleSubcategorieTime'>22:50</p>
                            </div>
                            <div className='video1 videoGap25-18'>
                                <img onClick={() => getModalState18(!modalState18)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #18</h3>
                                <p className='titleSubcategorieTime'>09:59</p>
                            </div>
                        </ContenedorVideos>
                        
                        <Modal
                            state= {modalState13}
                            handleState = {getModalState13}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 13.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState13(!modalState13)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState14}
                            handleState = {getModalState14}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 14.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState14(!modalState14)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState15}
                            handleState = {getModalState15}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 15.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState15(!modalState15)}>Terminar</Boton>
                        </Modal>
                         <Modal
                            state= {modalState16}
                            handleState = {getModalState16}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 16.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState16(!modalState16)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState17}
                            handleState = {getModalState17}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 17.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState17(!modalState17)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState18}
                            handleState = {getModalState18}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 18.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState18(!modalState18)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header># Semana 4</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            
                            <div className='video1 videoGap25-19'>
                                <img onClick={() => getModalState19(!modalState19)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #19</h3>
                                <p className='titleSubcategorieTime'>24:55</p>
                            </div>
                            <div className='video1 videoGap25-20'>
                                <img onClick={() => getModalState20(!modalState20)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #20</h3>
                                <p className='titleSubcategorieTime'>09:29</p>
                            </div>
                             <div className='video1 videoGap25-21'>
                                <img onClick={() => getModalState21(!modalState21)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #21</h3>
                                <p className='titleSubcategorieTime'>23:58</p>
                            </div>
                            <div className='video1 videoGap25-22'>
                                <img onClick={() => getModalState22(!modalState22)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #22</h3>
                                <p className='titleSubcategorieTime'>09:44</p>
                            </div>
                            <div className='video1 videoGap25-23'>
                                <img onClick={() => getModalState23(!modalState23)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #23</h3>
                                <p className='titleSubcategorieTime'>23:10</p>
                            </div>
                            <div className='video1 videoGap25-24'>
                                <img onClick={() => getModalState24(!modalState24)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #24</h3>
                                <p className='titleSubcategorieTime'>09:19</p>
                            </div>
                            <div className='video1 videoGap25-25'>
                                <img onClick={() => getModalState25(!modalState25)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #25</h3>
                                <p className='titleSubcategorieTime'>09:28</p>
                            </div>
                        </ContenedorVideos>
                       
                        <Modal
                            state= {modalState19}
                            handleState = {getModalState19}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 19.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState19(!modalState19)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState20}
                            handleState = {getModalState20}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 20.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState20(!modalState20)}>Terminar</Boton>
                        </Modal>
                         <Modal
                            state= {modalState21}
                            handleState = {getModalState21}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 21.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState21(!modalState21)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState22}
                            handleState = {getModalState22}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 22.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState22(!modalState22)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState23}
                            handleState = {getModalState23}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 23.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState23(!modalState23)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState24}
                            handleState = {getModalState24}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 24.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState24(!modalState24)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState25}
                            handleState = {getModalState25}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/GAP25/GAP 25 CAP 25.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState25(!modalState25)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
    </>
    )
}

const ContenedorVideos = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;

const Boton = styled.button`
    color: white;
    margin: 10px;
    padding: 5px;
    border-radius: 6px;
    border: none;
    background-color: #048870;
`

