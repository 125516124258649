import React, { useState } from 'react'
import { Modal } from '../../modal/Modal'
import styled from 'styled-components';
import '../stylesNavigation/navigationPages.css'
import ReactPlayer from 'react-player';
import { TbArrowBack } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';

export const Hiit = () => {
    const [modalState1, getModalState1] = useState(false);
    const [modalState2, getModalState2] = useState(false);
    const [modalState3, getModalState3] = useState(false);
    const [modalState4, getModalState4] = useState(false);
    const [modalState5, getModalState5] = useState(false);
    const [modalState6, getModalState6] = useState(false);
    const [modalState7, getModalState7] = useState(false);
    const [modalState8, getModalState8] = useState(false);
    // const [modalState9, getModalState9] = useState(false);
    // const [modalState10, getModalState10] = useState(false);
    return (
        <>
            <div className='titleCategory'>
				<a className='iconBcack' href='/quemagrasa'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Stamina</span>
			</div>
			<div className='titleSubcategorie'> 
				<Link to='/combat' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Hiit</h2>
				<Link to='/cardio' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
            <ContenedorVideos>
				<div className='video1 videoHiit1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #1</h3>
					<p className='titleSubcategorieTime'>15:44</p>
				</div>
				<div className='video1 videoHiit2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #2</h3>
					<p className='titleSubcategorieTime'>15:50</p>
				</div>
				<div className='video1 videoHiit3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #3</h3>
					<p className='titleSubcategorieTime'>22:14</p>
				</div>
				<div className='video1 videoHiit4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #4</h3>
					<p className='titleSubcategorieTime'>22:21</p>
				</div>
				<div className='video1 videoHiit5'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #5</h3>
					<p className='titleSubcategorieTime'>22:18</p>
				</div>
				<div className='video1 videoHiit6'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #6</h3>
					<p className='titleSubcategorieTime'>20:33</p>
				</div>
				<div className='video1 videoHiit7'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #7</h3>
					<p className='titleSubcategorieTime'>33:46</p>
				</div>
				<div className='video1 videoHiit8'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #8</h3>
					<p className='titleSubcategorieTime'>32:22</p>
				</div>
				{/* <div className='video1 videoHiit9'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #9</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div>
				<div className='video1 videoHiit10'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Hiit #10</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div> */}
			</ContenedorVideos>
            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true}
						controls 
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/20201116V02PBCWD - 15 Jota.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>


            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/20201116V03PACWD - 15 Jota.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/20201117V02PBCWD 15- Lusy.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/20201126V02PCFWD 15 - Jota.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState5}
				handleState = {getModalState5}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/20201126V03PCFWD 15 Jota.mp4'>
					</ReactPlayer>
			</Modal>
			<Modal
				state= {modalState6}
				handleState = {getModalState6}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/20210107V06PBCWD - 15 Lusy.mp4'>
					</ReactPlayer>
			</Modal>
			<Modal
				state= {modalState7}
				handleState = {getModalState7}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/20210108V02PAFWD 30 Lusy.mp4'>
					</ReactPlayer>
			</Modal>
			<Modal
				state= {modalState8}
				handleState = {getModalState8}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/20210114V03PCFWD - 30 Lusy.mp4'>
					</ReactPlayer>
			</Modal>
			{/* <Modal
				state= {modalState9}
				handleState = {getModalState9}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/'>
					</ReactPlayer>
			</Modal>
			<Modal
				state= {modalState10}
				handleState = {getModalState10}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Hiit/'>
					</ReactPlayer>
			</Modal> */}

        </>
    )
}


const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;
