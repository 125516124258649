import React from 'react'
import '../navigation/stylesNavigation/navigationCategories.css'
import { Link } from 'react-router-dom'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md'

export const Funcional = () => {
    return (
        <div>
            <div className='wrapperCategorie'>
                <Link to='/cuerpoYmente' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
                <img className='logoCross' src='/assets/images/Cross.png' alt='crossLogo'></img>
                <Link to='/quemagrasa' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
            </div>
            <p className='paragraphCategorie'>En Cross te ofrecemos entrenamientos completos y funcionales para llevar tu cuerpo al siguiente nivel.</p>
            <div className='categoriesWrapper'>
                <Link className='linkSubcategorie subcategorie1' to="/movilidad"></Link>
                <Link className='linkSubcategorie subcategorie2' to="/metabolico"></Link>
                <Link className='linkSubcategorie subcategorie3' to="/circuito"></Link>
            </div>
        </div>
    )
}
