import { Modal } from '../../modal/Modal';
import React, { useState } from 'react'
import ReactPlayer from 'react-player';
import '../stylesNavigation/navigationPages.css'
import styled from 'styled-components';
import { TbArrowBack } from 'react-icons/tb';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import { Link } from 'react-router-dom';

export const Combat = () => {
    const [modalState1, getModalState1] = useState(false);
    const [modalState2, getModalState2] = useState(false);
    const [modalState3, getModalState3] = useState(false);
    const [modalState4, getModalState4] = useState(false);
    const [modalState5, getModalState5] = useState(false);
    const [modalState6, getModalState6] = useState(false);
    const [modalState7, getModalState7] = useState(false);
    const [modalState8, getModalState8] = useState(false);
    const [modalState9, getModalState9] = useState(false);
    // const [modalState10, getModalState10] = useState(false);
    return (
        <>
            <div className='titleCategory'>
				<a className='iconBcack' href='/quemagrasa'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Stamina</span>
			</div>
			<div className='titleSubcategorie'> 
				<Link to='/cardio' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Combat</h2>
				<Link to='/hiit' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
            <ContenedorVideos>
				<div className='video1 videoCombat1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #1</h3>
					<p className='titleSubcategorieTime'>14:10</p>
				</div>
				<div className='video1 videoCombat2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #2</h3>
					<p className='titleSubcategorieTime'>20:11</p>
				</div>
				<div className='video1 videoCombat3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #3</h3>
					<p className='titleSubcategorieTime'>40:13</p></div>
				<div className='video1 videoCombat4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #4</h3>
					<p className='titleSubcategorieTime'>40:04</p>
				</div>
				<div className='video1 videoCombat5'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #5</h3>
					<p className='titleSubcategorieTime'>20:11</p>
				</div>
				<div className='video1 videoCombat6'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #6</h3>
					<p className='titleSubcategorieTime'>40:13</p>
				</div>
				<div className='video1 videoCombat7'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #7</h3>
					<p className='titleSubcategorieTime'>16:57</p>
				</div>
				<div className='video1 videoCombat8'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #8</h3>
					<p className='titleSubcategorieTime'>41:08</p>
				</div>
				<div className='video1 videoCombat9'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #9</h3>
					<p className='titleSubcategorieTime'>18:36</p>
				</div>
				{/* <div className='video1 videoCombat10'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Combat #10</h3>
					<p className='titleSubcategorieTime'>35:32</p>
				</div> */}
			</ContenedorVideos>
            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true}
						controls 
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/20200930V01PBCAWD 15 min - Lusy.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>


            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/20201117V03PBCWD - 15 MIN Lusy.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer
						className='modalPlayer'
						playing = {true} 
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/20201118V04PBCWD - 45 min - Lusy.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/20201119V06PBCWD - 45 MIN Lusy.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState5}
				handleState = {getModalState5}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/20201124V03PBNCWD - 15MIN Lusy.mp4'>
					</ReactPlayer>
			</Modal>
			<Modal
				state= {modalState6}
				handleState = {getModalState6}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/20210105V03PBCWD - 45 Lusy.mp4'>
					</ReactPlayer>
			</Modal>
			<Modal
				state= {modalState7}
				handleState = {getModalState7}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/20210107V05PBCWD - 15 MIN Lusy.mp4'>
					</ReactPlayer>
			</Modal>
			<Modal
				state= {modalState8}
				handleState = {getModalState8}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/20210112V01PBCWDC - 45 Lusy.mp4'>
					</ReactPlayer>
			</Modal>
			<Modal
				state= {modalState9}
				handleState = {getModalState9}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/20210112V02PBCWDC 45 Lusy.mp4'>
					</ReactPlayer>
			</Modal>
			{/* <Modal
				state= {modalState10}
				handleState = {getModalState10}
				>
					<ReactPlayer 
						className='modalPlayer'
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Stamina/Combat/'>
					</ReactPlayer>
			</Modal> */}
    </>
    )
}


const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;