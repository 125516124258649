import React, { useState } from 'react'
import '../../navigation/stylesNavigation/navigationCategories.css'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { Modal } from '../../modal/Modal';
import ReactPlayer from 'react-player';
import Accordion from 'react-bootstrap/Accordion';
import { TbArrowBack } from 'react-icons/tb';


export const PlanEntrenamiento1 = () => {
    const [modalState1, getModalState1] = useState(false)
    const [modalState2, getModalState2] = useState(false)
    const [modalState3, getModalState3] = useState(false)
    const [modalState4, getModalState4] = useState(false)
    const [modalState5, getModalState5] = useState(false)
    const [modalState6, getModalState6] = useState(false)
    const [modalState7, getModalState7] = useState(false)
    const [modalState8, getModalState8] = useState(false)
    const [modalState9, getModalState9] = useState(false)
    const [modalState10, getModalState10] = useState(false)
    const [modalState11, getModalState11] = useState(false)
    const [modalState12, getModalState12] = useState(false)
    return (

        <>
            <div>
                <div className='titleCategory'>
				    <a className='iconBcack' href='/programasEntrenamiento'><TbArrowBack/></a>
				    <span className='titleCategorie'> Categoría: Plan de Entrenamiento</span>
			    </div>
                <div className='wrapperCategorie'>
                    <Link to='/planEntrenamiento5' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
                    <h2 className='titlePlan'>En forma tras el embarazo</h2>
                    <Link to='/planEntrenamiento2' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
                </div>
                <p className='paragraphCategorie'>Plan de entrenamiento específico de 4 semanas para ponerse en forma después del embarazo.</p>
            </div>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header># Semana 1</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTrasEmbarazo1'>
                                <img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #1</h3>
                                <p className='titleSubcategorieTime'>30:05</p>
                            </div>
                            <div className='video1 videoTrasEmbarazo2'>
                                <img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #2</h3>
                                <p className='titleSubcategorieTime'>29:55</p>
                            </div>
                            <div className='video1 videoTrasEmbarazo3'>
                                <img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #3</h3>
                                <p className='titleSubcategorieTime'>30:20</p>
                            </div>
                            
                        </ContenedorVideos>
                        <Modal
                            state= {modalState1}
                            handleState = {getModalState1}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width= '100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+PonteEnFormaDespuesDelEmbarazo1.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState1(!modalState1)}> Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState2}
                            handleState = {getModalState2}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 2.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState2(!modalState2)}> Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState3}
                            handleState = {getModalState3}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 3.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState3(!modalState3)}> Terminar</Boton>
                        </Modal>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header># Semana 2</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTrasEmbarazo4'>
                                <img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #4</h3>
                                <p className='titleSubcategorieTime'>30:22</p>
                            </div>
                            <div className='video1 videoTrasEmbarazo5'>
                                <img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #5</h3>
                                <p className='titleSubcategorieTime'>30:24</p>
                            </div>
                            <div className='video1 videoTrasEmbarazo6'>
                                <img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #5</h3>
                                <p className='titleSubcategorieTime'>30:32</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState4}
                            handleState = {getModalState4}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 4.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState4(!modalState4)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState5}
                            handleState = {getModalState5}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 5.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState5(!modalState5)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState6}
                            handleState = {getModalState6}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 6.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState6(!modalState6)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header># Semana 3</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTrasEmbarazo7'>
                                <img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #7</h3>
                                <p className='titleSubcategorieTime'>29:24</p>
                            </div>
                            <div className='video1 videoTrasEmbarazo8'>
                                <img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #8</h3>
                                <p className='titleSubcategorieTime'>29:39</p>
                            </div>
                            <div className='video1 videoTrasEmbarazo9'>
                                <img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #9</h3>
                                <p className='titleSubcategorieTime'>30:11</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState7}
                            handleState = {getModalState7}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 7.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState7(!modalState7)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState8}
                            handleState = {getModalState8}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 8.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState8(!modalState8)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState9}
                            handleState = {getModalState9}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 9.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState9(!modalState9)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header># Semana 4</Accordion.Header>
                    <Accordion.Body>
                        <ContenedorVideos>
                            <div className='video1 videoTrasEmbarazo10'>
                                <img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #10</h3>
                                <p className='titleSubcategorieTime'>30:00</p>
                            </div>
                            <div className='video1 videoTrasEmbarazo11'>
                                <img onClick={() => getModalState11(!modalState11)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #11</h3>
                                <p className='titleSubcategorieTime'>29:29</p>
                            </div>
                            <div className='video1 videoTrasEmbarazo12'>
                                <img onClick={() => getModalState12(!modalState12)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
                                <h3 className='titleVideo'>Sesión #12</h3>
                                <p className='titleSubcategorieTime'>31:02</p>
                            </div>
                        </ContenedorVideos>
                        <Modal
                            state= {modalState10}
                            handleState = {getModalState10}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 10.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState10(!modalState10)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState11}
                            handleState = {getModalState11}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 11.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState11(!modalState11)}>Terminar</Boton>
                        </Modal>
                        <Modal
                            state= {modalState12}
                            handleState = {getModalState12}
                            >
                                <ReactPlayer
                                    className='modalPlayer'
                                    controls 
                                    playing = {true}
                                    width='100%'
                                    url='assets/videos/programas/EnFormaTrasEmbarazo/Sg+ Ponte En Forma Después Del Embarazo 12.mp4'>
                                </ReactPlayer>
                                <Boton onClick={()=> getModalState12(!modalState12)}>Terminar</Boton>
                        </Modal>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}


const ContenedorVideos = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;

const Boton = styled.button`
    color: white;
    margin: 10px;
    padding: 5px;
    border-radius: 6px;
    border: none;
    background-color: #048870;
`