import React, { useState } from 'react'
import { Modal } from '../../modal/Modal'
import styled from 'styled-components';
import '../stylesNavigation/navigationPages.css'
import ReactPlayer from 'react-player';
import { TbArrowBack } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';

export const Pilates = () => {
    const [modalState1, getModalState1] = useState(false);
    const [modalState2, getModalState2] = useState(false);
    const [modalState3, getModalState3] = useState(false);
    const [modalState4, getModalState4] = useState(false);
    const [modalState5, getModalState5] = useState(false);
    const [modalState6, getModalState6] = useState(false);
    const [modalState7, getModalState7] = useState(false);
    const [modalState8, getModalState8] = useState(false);
    const [modalState9, getModalState9] = useState(false);
    const [modalState10, getModalState10] = useState(false);

    return (
        <>
           	<div className='titleCategory'>
				<a className='iconBcack' href='/cuerpoYMente'><TbArrowBack/></a>
				<span className='titleCategorie'> Categoría: Agility</span>
			</div>
			<div className='titleSubcategorie'> 
				<Link to='/barre' className='btn buttonNavigationSub'><MdOutlineArrowBackIosNew/></Link>
				<h2>Pilates</h2>
				<Link to='/yoga' className='btn buttonNavigationSub'><MdOutlineArrowForwardIos/></Link>
			</div>
            <ContenedorVideos>
				<div className='video1 videoPilates1'>
					<img onClick={() => getModalState1(!modalState1)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #1</h3>
					<p className='titleSubcategorieTime'>20:48</p>
				</div>
				<div className='video1 videoPilates2'>
					<img onClick={() => getModalState2(!modalState2)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #2</h3>
					<p className='titleSubcategorieTime'>26:30</p>
				</div>
				<div className='video1 videoPilates3'>
					<img onClick={() => getModalState3(!modalState3)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #3</h3>
					<p className='titleSubcategorieTime'>31:00</p>
				</div>
				<div className='video1 videoPilates4'>
					<img onClick={() => getModalState4(!modalState4)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #4</h3>
					<p className='titleSubcategorieTime'>40:33</p>
				</div>
				<div className='video1 videoPilates5'>
					<img onClick={() => getModalState5(!modalState5)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #5</h3>
					<p className='titleSubcategorieTime'>23:23</p>
				</div>
				<div className='video1 videoPilates6'>
					<img onClick={() => getModalState6(!modalState6)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #6</h3>
					<p className='titleSubcategorieTime'>30:24</p>
				</div>
				<div className='video1 videoPilates7'>
					<img onClick={() => getModalState7(!modalState7)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #7</h3>
					<p className='titleSubcategorieTime'>26:33</p>
				</div>
				<div className='video1 videoPilates8'>
					<img onClick={() => getModalState8(!modalState8)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #8</h3>
					<p className='titleSubcategorieTime'>43:01</p>
				</div>
				<div className='video1 videoPilates9'>
					<img onClick={() => getModalState9(!modalState9)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #9</h3>
					<p className='titleSubcategorieTime'>27:10</p>
				</div>
				<div className='video1 videoPilates10'>
					<img onClick={() => getModalState10(!modalState10)} className='iconPlay' src='/assets/images/boton-de-play.png' alt='iconPlay'></img>
					<h3 className='titleVideo'>Pilates #10</h3>
					<p className='titleSubcategorieTime'>27:46</p>
				</div>
			</ContenedorVideos>
            <Modal
				state= {modalState1}
				handleState = {getModalState1}
				>
					<ReactPlayer
						className='modalPlayer'
						controls 
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20201110V04papwd 15 Min - Jota.mp4'>
					</ReactPlayer>
					{/* <Boton onClick={()=> getModalState1(!modalState1)}>Aceptar 1</Boton> */}
			</Modal>


            <Modal
				state= {modalState2}
				handleState = {getModalState2}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20201113V03papwd 30Min - Dama.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState3}
				handleState = {getModalState3}
				>
					<ReactPlayer 
						className='modalPlayer'
						controls
						playing = {true}
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20201113V05papwd 15Min - Jota.mp4'>
					</ReactPlayer>
				

			</Modal>

			<Modal
				state= {modalState4}
				handleState = {getModalState4}
				>
					<ReactPlayer
						className='modalPlayer' 
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20201202V04papawd - 45Min - Greta.mp4'>
					</ReactPlayer>
					
			</Modal>

			<Modal
				state= {modalState5}
				handleState = {getModalState5}
				>
					<ReactPlayer 
						className='modalPlayer' 
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20201215V01pls - 30Min - Jota.mp4'>
					</ReactPlayer>
			</Modal>

			<Modal
				state= {modalState6}
				handleState = {getModalState6}
				>
					<ReactPlayer 
						className='modalPlayer' 
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20201215V02pls - 30Min - Jota.mp4'>
					</ReactPlayer>
			</Modal>

			<Modal
				state= {modalState7}
				handleState = {getModalState7}
				>
					<ReactPlayer 
						className='modalPlayer' 
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20210111V01papwdc 30Min - Greta.mp4'>
					</ReactPlayer>
			</Modal>

			<Modal
				state= {modalState8}
				handleState = {getModalState8}
				>
					<ReactPlayer 
						className='modalPlayer' 
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20210111V02papwd 45Min - Greta.mp4'>
					</ReactPlayer>
			</Modal>

			<Modal
				state= {modalState9}
				handleState = {getModalState9}
				>
					<ReactPlayer 
						className='modalPlayer' 
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20210113V01papwd 30Min - Greta.mp4'>
					</ReactPlayer>
			</Modal>

			<Modal
				state= {modalState10}
				handleState = {getModalState10}
				>
					<ReactPlayer 
						className='modalPlayer' 
						playing = {true}
						controls
						width='100%'
						url='assets/videos/Workouts/Agility/Pilates/20210119V07papwd 30Min - Dama.mp4'>
					</ReactPlayer>
			</Modal>
        </>
    )
}


const ContenedorVideos = styled.div`
	min-height: 70vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;
